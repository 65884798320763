@charset "utf-8";

/* **************************
 　　共通
************************** */
html,body {
	width:100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	color: #464646;
	font-size:15px;
	line-height: 1.8em;

}
a {text-decoration: none;color:#444444;}
a:hover { color:#666666;  text-decoration: none;}
a:hover img { filter:alpha(opacity=50); -moz-opacity:0.50; opacity:0.50;text-decoration: none;}

.clear {clear: both;}

.txt01{font-size:24px;font-weight: bold;}
.txt02{font-weight: bold;}
.txt03{font-size:33px;font-weight: bold;}
.txt-right{text-align:right;}
.txt-bold{font-weight: bold;}

#wrapper{width:100%;overflow: hidden;position: relative;}


/* **************************
 　　ヘッダー
************************** */

header{position:fixed;}

h1{width:130px;}
h1 img{
	width:100%;
	vertical-align: bottom;
}
#wrap01{
	width:100%;
	position: fixed;
	margin:0;
	padding: 15px;
	background:#fff;
	z-index:10;
}
.header{
	&-left{}
	&-right{}
	&-title{
		display: none;
	}
}


/* **************************
 　　バーガーボタン
************************** */


.menu-trigger,
.menu-trigger span {
	display: inline-block;
	transition: all .4s;
	box-sizing: border-box;
}
.menu-trigger {
	position: fixed;
	top: 17px;
	right: 10px;
	width: 40px;
	height: 40px;
	z-index: 9999;
	color:#000;
	border-radius: 4px;
}
a.menu-trigger:hover{color:#000;}
.menu-trigger span {
	position: absolute;
	z-index: 9999;
	left: 8px;
	width: 75%;
	height: 3px;
	background-color: #ec7300;
	border-radius: 4px;
}
.menu-trigger span:nth-of-type(1) {top: 9px;}
.menu-trigger span:nth-of-type(2) {top: 20px;}
.menu-trigger span:nth-of-type(3) {bottom: 6px;}
.menu-trigger.active span:nth-of-type(1) {
	-webkit-transform: translateY(10px) rotate(-45deg);
	transform: translateY(9px) rotate(-45deg);
	background-color: #fff;
}
.menu-trigger.active span:nth-of-type(2) {opacity: 0;}
.menu-trigger.active span:nth-of-type(3) {
	-webkit-transform: translateY(-12px) rotate(45deg);
	transform: translateY(-12px) rotate(45deg);
	background-color: #fff;
}


/* **************************
 　　オーバーレイナビ
************************** */

#overlay{
	display: none;
	width: 100%;
	height:100%;
	text-align: left;
	position: fixed;
	top: 0;
	left:0;
	z-index: 9999;
	background: #ec7300;
	margin:0;
	padding: 0 8%;
	box-sizing: border-box;
}
#overlay ul{
	margin-top:40px;
	padding-left: 0px;
}
#overlay ul.line{
	margin-top: 0;
}
#overlay ul li{
	margin:0;
	padding:0;
	list-style-type: none;
}
#overlay ul li a{
	display:block;
	 padding: 15px 0;
	color: #fff;
	text-decoration: none;
	font-size: 15px;
	line-height: 1.8em;
}
#overlay ul li a:hover{color:rgba(255,255,255,0.6);}
#overlay .logo{padding:30px 0 0 0;text-align:center;}
#overlay .logo img{width:30%;}
#overlay .header-title{
	display: block;
	color: #fff;
	font-size: 14px;
	text-align: center;
}

.navi1{
	border-top:2px solid rgba(255,255,255,0.6);
	border-bottom:2px solid rgba(255,255,255,0.6);
}
.navi2,
.navi3{
	border-bottom:2px solid rgba(255,255,255,0.6);
}
.navi5{display: none;}
.navi5 ul{width:100%;}
.navi5 li{
	margin:0;
	padding:0;
	display:inline-block;
	width:50%;
	box-sizing: border-box;
	text-align:center;
	font-size: 80%;
}
.navi5 li:first-child{	border-right:2px solid rgba(255,255,255,0.6);}
.navi5 li a{margin:0;padding:0;}
.navi5 li img{width:40px;padding:0 0 8px 0;}
.navi6{
	width:100%;
	text-align:center;
}
.navi6 a{
	display:block;
	padding:0 0 5% 0;
}
.line li{vertical-align:top;box-sizing: border-box;float: left;}


/* **************************
 　　メインイメージ
************************** */

#main01{
	padding:0;
	width: 100%;
	background: url(../img/image01-1.jpg) center center / cover no-repeat;
	animation: fadeIn 8s ease 0s 1 normal;
	-webkit-animation: fadeIn 8s ease 0s 1 normal;
	display:table;
}
#main01-s{display: none;}
#main01-p{display: none;}
@keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}
.box{
	display:table-cell;
	vertical-align:middle;
}
.m-logo{
	width:458px;
	margin:0 auto;
	animation: fadeIn 8s ease 0s 1 normal;
	-webkit-animation: fadeIn 8s ease 0s 1 normal;
}
@keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}
.m-text{
	font-family:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	padding:25px 0 0 0;
	color:#fff;
	font-size:17px;
	text-align:center;
	line-height: 1.8em;
	letter-spacing: 0.2em;
}


/* **************************
 　　ニュース
************************** */

#news-b{position: relative;}
#news{
	position:absolute;top:-120px;
	width: 100%;
	height: 120px;
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	text-align:center;
}
#news h2{
	font-family:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	color:#fff;
	font-size:25px;
	font-weight: normal;
	text-align:left;
	line-height: 130%;
	display: inline-block;
	margin:30px auto;
	padding:0 30px;
	border-left:8px solid #fff;
	border-right:8px solid #fff;
}
#news-s{display:none;}


/* **************************
 　　ご挨拶
************************** */

.greet{
	font-family:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	width:100%;
	margin:70px auto;
}
.greet h3{
	margin:0 5%;
	padding:0 0 10px 0;
	font-size:32px;
	line-height: 1.5em;
	font-weight: bold;
	text-align:center;
	border-bottom:1px solid #000;
}
.greet p{
	margin:0 5%;
	padding:10px 0 0 0;
	font-size:19px;
	line-height: 1.8em;
}

/* **************************
 　　内覧会のご案内
************************** */

#guide-h{
	width:100%;
	background:#f1ede2;
	padding:70px 0;
}
#guide{
	width:90%;
	margin:0 5%;
}
.pc{display:none;}
.sp{display:none;}
#guide h4{
	padding-bottom: 30px;
	font-size:30px;
	line-height: 1.5em;
	color:#ee7800;
	border-bottom:1px solid #fff;
	text-align:center;
}
#guide h4:before{
	content:" ";
	display:inline-block;
	width:43px;
	height:52px;
	background:url(../img/icon01.png);
	background-size:contain;
	vertical-align:middle;
	margin-right:5px;
	position: relative;
	top: -5px;
	left:0;
}

.g-group04{width:100%;}
.g-group05{width:60%;float:left;}
#g01{
	padding:20px 20px 20px 0;
	font-size:15px;
	line-height: 1.8em;
	padding-bottom:10px;

}
#g02{
	position: relative;
	padding-bottom:340px;
}
#g02 .icon02{
	display:block;
	width:112px;
	height:121px;
	margin:0 auto;
	position: absolute;
	z-index:1;
}
#g02 .icon02 img{width:100%;}
#g02 .con01{
	display:block;
	width:95%;
	background:#fff;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	margin:0 0 0 0;
	padding:52px 18px 18px 18px;
	box-sizing: border-box;
	font-size:15px;
	line-height: 1.8em;
	position: absolute;
	top:75px;

}
.con01 h5{
	font-size:17px;
	color:#ee7800;
	border-bottom:1px solid #ee7800;
	width:100%;
	padding-bottom:7px;
	margin-bottom:10px;
	text-align:left;
}
.con01 table{width:100%;}
.con01 th{
	width:35%;
	padding:5px 5px;
	text-align:left;
	word-break : break-all;
}
.con01 td{
	padding:5px 5px;
	text-align:left;
	word-break : break-all;
}

#g03{
	position: relative;
	padding-bottom:320px;
}
#g03 .icon02{
	display:block;
	width:113px;
	height:120px;
	margin:0 auto;
	z-index:1;
	position: absolute;
}
#g03 .icon02 img{width:100%;}
#g03 .con01{
	display:block;
	width:95%;
	box-sizing: border-box;
	background:#fff;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	margin:0;
	padding:60px 18px 18px 18px;
	font-size:15px;
	line-height: 1.8em;
	position: absolute;
	top:65px;
}
#g04{width:100%;margin: 0;padding:22px 0;}
#g04 img{width:100%;}
#g05{
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	margin:5px 0 0 0 ;
	padding:20px;
	text-align:center;
	color:#fff;
	line-height: 150%;
	font-size:18px;
}
#g05 p{margin-bottom:16px;border-bottom:1px solid #fff;}

.g-group06{width:40%;float:left;}
.g-group01{display:none;}
.g-group02{display:none;}
.g-group03{display:none;}
.g-group07{display:none;}
.g-group08{display: none;}
.flex{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}
.map h5{
	font-size:20px;
	font-weight: bold;
	padding-bottom:12px;
}

.center{text-align:center;}


/* **************************
 　　施設概要
************************** */
.facility {
	width:90%;
	margin:0 5%;
	padding:30px 0 0 0;
}
.facility h5{font-size:20px;padding:0 0 10px 0;}
.summary{
	width:100%;
	margin:20px auto 20px auto;
	padding:35px 0 20px 0;
	border-top:1px solid #999999;
}
.summary tr{border-bottom:1px solid #999999;}
.summary th{
	padding:28px 30px;
	font-weight: normal;
	font-size:16px;
	text-align:left;
	line-height: 150%;
	vertical-align:middle;
}
.summary td{
	padding:28px 30px;
	font-weight: normal;
	font-size:16px;
	text-align:left;
	line-height: 150%;
}
.map {
	width:100%;
	margin:60px auto 0 auto;
}
.map iframe{width:100%;height:400px;}


/* **************************
 　　法人概要
************************** */

#corpo{
	width:90%;
	margin:0 5%;
	padding:50px 0;
}
#corpo h3{
	padding: 18px 0 10px 0;
	font-size:32px;
	line-height: 1.5em;
	font-weight: bold;
	color:#ee7800;
	text-align:center;
}
#work{
	padding-top: 70px;
}

/* **************************
 　　フッター
************************** */

#footer{
	display: block;
	width:100%;
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	color:#fff;
	padding:20px 0;
	text-align:center;
}
#footer ul{display: inline-block;margin:20px 0;}
#footer li{
	display: table-cell;
	vertical-align: middle;
	list-style-type: none;
	margin:0;
	padding:0 40px;
	border-right:1px solid #fff;
	font-size:14px;
}
#footer li:last-child{border:none;}
#footer img{width:180px;}
#footer .copy{font-size:12px;}


/* **************************
 　　ページトップ
************************** */
#page-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index:20;
}
#page-top a {
	text-align: center;
	display: block;
}
#page-top a:hover img{ opacity: 0.6;}

#page-top img{width:90px;}


/* **************************
 　　SP固定ナビ
************************** */
#sp-fix{display:none;}


/* **************************
 　　js周り
************************** */

/* ------------- スクロールフェードインDown用 ------------- */
.fadeInDown {
	-webkit-animation-fill-mode:both;
	-ms-animation-fill-mode:both;
	animation-fill-mode:both;
	-webkit-animation-duration:1s;
	-ms-animation-duration:1s;
	animation-duration:1s;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	visibility: visible !important;
}
@-webkit-keyframes fadeInDown {
	0% { opacity: 0; -webkit-transform: translateY(20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes fadeInDown {
	0% { opacity: 0; -webkit-transform: translateY(20px); -ms-transform: translateY(20px); transform: translateY(20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
}

.fadeInDown1 {
	-webkit-animation-fill-mode:both;
	-ms-animation-fill-mode:both;
	animation-fill-mode:both;
	-webkit-animation-duration:3s;
	-ms-animation-duration:3s;
	animation-duration:3s;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	visibility: visible !important;
}
@-webkit-keyframes fadeInDown1 {
	0% { opacity: 0; -webkit-transform: translateY(30px); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes fadeInDown1 {
	0% { opacity: 0; -webkit-transform: translateY(30px); -ms-transform: translateY(30px); transform: translateY(30px); }
	100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
}
/* ------------- スクロールフェードインDown用 END ------------- */
